<template>
  <div class="wrapper">
    <!-- 适老化改造 -->
    <!-- <vxe-toolbar>
			<template v-slot:buttons>
				<vxe-button status="my-orange" @click="showSearch(false)" v-if="seachStatus">隐藏查询</vxe-button>
				<vxe-button status="my-orange" icon="fa fa-search" @click="showSearch(true)" v-if="!seachStatus">查询</vxe-button>
				<vxe-button status="my-orange" icon="" @click="goPage('add')">下载报告</vxe-button>
			</template>
		</vxe-toolbar>-->
    <div class="search">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)"
                @reset="handleSearch(true, true)">
        <vxe-form-item title="查询日期" span="12">
          <el-date-picker
              v-model="listQuery.param.time"
              size="small"
              clearable
              type="datetimerange"
              align="center"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="请选择开始日期"
              end-placeholder="请选择结束日期"
              style="minWidth: 100%;"
          />
        </vxe-form-item>
        <vxe-form-item title="老人姓名" span="6">
          <vxe-input v-model="listQuery.param.oldPersonName" placeholder="请输入老人姓名" clearable/>
        </vxe-form-item>
        <vxe-form-item title="联系方式" span="6">
          <vxe-input v-model="listQuery.param.oldPersonContract" placeholder="请输入联系方式" clearable/>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">应用</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>

    <div style="margin-top: 20px">
      <div class="flex justify-between table-top align-center">
        <div class="flex align-center">
          <el-checkbox style="margin-left: 24px;margin-right: 15px;" @change="changeSelect"></el-checkbox>
          <el-button type="text" size="small">全选</el-button>
        </div>
        <div class="flex align-center" style="margin-right:35px;">
          <el-button type="success" size="small" @click="passAll">通过审核</el-button>
          <el-button type="danger" size="small" @click="unPassAll">审核不通过</el-button>
        </div>
      </div>
      <el-table ref="multipleTable" @selection-change="changeSelection" :data="tableData" border show-overflow-tooltip
                style="width: 100%">
        <el-table-column align="center" type="selection" label="" width="60">
        </el-table-column>
        <el-table-column type="index" label="序号" width="60" align="center"/>
        <el-table-column align="center" prop="name" label="服务对象"/>
        <el-table-column align="center" prop="sex" label="性别"/>
        <el-table-column align="center" prop="age" label="年龄"/>
        <el-table-column align="center" prop="contract" label="联系方式"/>
        <el-table-column align="center" prop="address" label="申请改造住宅地址"/>
        <el-table-column align="center" prop="serviceName" label="服务名称">
          <template slot-scope="{row}">
            <div v-if="row.reformType ==='ARCHIVE'">适老化改造档案</div>
            <div v-if="row.reformType ==='BASIC_INFO'">适老化改造基本信息确认</div>
            <div v-if="row.reformType ==='APPLY'">适老化改造申请</div>
            <div v-if="row.reformType ==='PERSON_ASSESS'">适老化改造老年人评估</div>
            <div v-if="row.reformType ==='REQUIRE_CONFIRM'">适老化改造需求确认</div>
            <div v-if="row.reformType ==='REQUIRE_CONSTRUCT'">适老化改造施工</div>
            <div v-if="row.reformType ==='REQUIRE_ACCEPT'">适老化改造需求验收</div>
            <div v-if="row.reformType ==='REFORM_GIVE_UP'">放弃改造承诺书</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="applyTime" label="申请时间"/>
        <el-table-column align="center" prop="auditState" label="审核状态"></el-table-column>
        <el-table-column align="center" label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="goDetail(scope.row)">详情</el-button>
            <el-button type="success" size="small" @click="passOne(scope.row)">通过审核</el-button>
            <el-button type="danger" size="small" @click="unPassOne(scope.row)">审核不通过</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="page">
      <vxe-pager
          :current-page.sync="tablePage.currentPage"
          :page-size.sync="tablePage.pageSize"
          :page-sizes="tablePage.pageSizes"
          :total="tablePage.totalResult"
          :layouts="layouts"
          @page-change="pageChange"
      />
    </div>

    <el-dialog :title="formTitle" :visible.sync="dialogFormVisible" :show-close="true" :close-on-click-modal="false">
      <el-form :model="form" label-position="left">
        <el-form-item label="审核人姓名" :label-width="formLabelWidth">
          <el-input v-model="form.lastAuditor" autocomplete="off"/>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="form.remark" autocomplete="off"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="()=>{dialogFormVisible = false,form={}}">取 消
        </el-button>
        <el-button type="primary" @click="addOrEdit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import UploadImg from '@/components/UploadImg.vue'
import axios from 'axios'
import api from '@/store/API/api'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'

export default {
  components: {
    UploadImg, ...CustomTemplate
  },
  data() {
    return {
      btndisabled: false,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      chooseId: '',
      formTitle: '',
      form: {
        id: '',
        lastAuditState: '',
        lastAuditor: '',
        remark: ''
      },
      formSelectId: [],
      pictureList: [],
      qualityInspectReportList: [],
      fileData: null,
      fileName: '',
      importVisible: false,
      uploadLoading: false,
      tableData: [],
      // ismore:false,
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      listQuery: {
        param: {
          time: '',
          belongs: 0,
          oldPersonName: '',
          oldPersonContract: ''
        }
      }
    }
  },
  created() {
    this.getLists()
  },
  computed: {
    ...mapState([
      'layouts'
    ])
  },
  methods: {
    ...mapActions([
      'elderlyAuditList',
      'elderlyAuditAudit',
      'elderlyAuditAuditAll',
      'elderlyAuditDelete'
    ]),
    changeSelect(e) {
      if (e) {
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        console.log(this.$refs.multipleTable)
        this.$refs.multipleTable.clearSelection()
      }
      console.log(e)
    },
    changeSelection(e) {
      this.formSelectId = []
      e.forEach(item => {
        this.formSelectId.push(item.id)
      })
    },
    passAll() {
      if (this.formSelectId.length <= 0) {
        this.$message.warning('请选择数据')
        return
      }
      this.form = {
        idSet: this.formSelectId,
        type: 'all',
        lastAuditor: '',
        lastAuditState: 'OK',
        remark: ''
      }
      this.formTitle = '通过审核！'
      this.dialogFormVisible = true
    },
    passOne(row) {
      this.form = {
        id: row.id,
        type: 'one',
        lastAuditor: '',
        lastAuditState: 'OK',
        remark: ''
      }
      this.formTitle = '通过审核！'
      this.dialogFormVisible = true
    },
    unPassAll() {
      if (this.formSelectId.length <= 0) {
        this.$message.warning('请选择数据')
        return
      }
      this.form = {
        idSet: this.formSelectId,
        type: 'all',
        lastAuditor: '',
        lastAuditState: 'NO',
        remark: ''
      }
      this.formTitle = '拒绝审核！'
      this.dialogFormVisible = true
    },
    unPassOne(row) {
      this.form = {
        id: row.id,
        type: 'one',
        lastAuditor: '',
        lastAuditState: 'NO',
        remark: ''
      }
      this.formTitle = '拒绝审核！'
      this.dialogFormVisible = true
    },

    goDetail(row) {
      this.$router.push({
        name: 'oldChangeExamineAuditedDetail',
        query: {
          id: row.id
        }
      })
    },
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
      }
      this.getLists(resetCurrentPage)
    },
    // 分页功能
    pageChange(item) {
      if (item.type === 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    goPage(type, itemData) {
      this.$router.push({
        name: 'AddPerson',
        params: {
          type: type,
          id: itemData.oldPersonId
        },
        query: {
          oldPersonId: itemData.oldPersonId
        }
      })
    },
    // 查询列表
    getLists() {
      const params = {
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
        auditLinkIsEnd: false
      }
      if (this.listQuery.param.time !== '') {
        params.applyTimeFrom = this.listQuery.param.time[0]
        params.applyTimeTo = this.listQuery.param.time[1]
      }
      if (this.listQuery.param.oldPersonContract !== '') {
        params.oldPersonContract = this.listQuery.param.oldPersonContract
      }
      if (this.listQuery.param.oldPersonName !== '') {
        params.oldPersonName = this.listQuery.param.oldPersonName
      }
      this.elderlyAuditList(params).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.dataList
          this.tablePage.totalResult = res.data.total
        }
      })
      // const tableData = [{
      //   name: '安捷',
      //   sex: '男',
      //   age: '82',
      //   contact: '130****8759',
      //   address: '陕西省西安市莲湖区北园门街道东大街田家巷子楼18#-2-2层东',
      //   serviceName: '适老化改造申请',
      //   createTime: '2023.10.25',
      //   status: ''
      // }]
      // this.tableData = tableData
      // this.tablePage.totalResult = 1
    },
    addOrEdit() {
      if (this.form.type === 'all') {
        this.elderlyAuditAuditAll(this.form).then(res => {
          if (res.code === 200) {
            this.dialogFormVisible = false
            this.$message.success('审核完成')
            this.getLists()
          }
        })
      } else {
        this.elderlyAuditAudit(this.form).then(res => {
          if (res.code === 200) {
            this.dialogFormVisible = false
            this.$message.success('审核完成')
            this.getLists()
          }
        })
      }
    }
  }

}
</script>
<style>
.el-table thead tr th:first-child div {
  display: none !important;
}
</style>
<style lang="scss" scoped>
.wrapper {
  overflow: auto;

  .page {
    overflow: initial;
  }
}

.w-100 {
  width: 100% !important;
}

v-deep .el-form-item__label {
  text-align: left !important;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

.table-top {
  width: 100%;
  height: 56px;
  background: #FFFFFF;
  border: 1px solid #E9EBEC;
  border-bottom: none;
}
</style>
